import "src/css/globals/common.css";
import "src/css/globals/text.css";

export const NoMatch = () => {
  return (
    <div className="no-match" >
      <h1 className="nom-match-text-first">nomatch</h1>
    </div>
  )
}

export default NoMatch;
